import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/317f3503/src/components/layouts/index.js";
import IconListing from "../components/IconListing";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Icons`}</h1>
    <p>{`Datastory uses `}<a parentName="p" {...{
        "href": "https://nucleoapp.com/"
      }}>{`Nucleo app`}</a>{` to store and manage icons. Nucleo app contains both icons from the Nucleo library as well as Google Material icons. In addition, custom icons can be added. Currently, many of the Datastory UI icons are based on `}<a parentName="p" {...{
        "href": "https://iconsvg.xyz/"
      }}>{`iconsvg.xyz`}</a>{`. The Datastory icons are divided in two sets:`}</p>
    <ol>
      <li parentName="ol">{`Datastory flags`}</li>
      <li parentName="ol">{`Datastory UI icons`}</li>
    </ol>
    <p>{`To work with icons in Datastory you need to:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Download Nucleo`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Get access to the `}<a parentName="p" {...{
            "href": "https://www.dropbox.com/sh/7c5d8440pdws1m7/AADqb7mrO98M2S7fP6Mr7eHaa?dl=0"
          }}>{`Datastory project on Dropbox`}</a></p>
      </li>
    </ol>
    <p>{`Once you do that you should see `}<inlineCode parentName="p">{`Datastory-icons`}</inlineCode>{` under the Datastory project (highlighted in the image below). It should look something like this:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a4d3690a486a9414a233772cc579ebf1/2f950/nucleo.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAAB1UlEQVQoz31S227TQBD1z3Jr4qRIVOKJH+hvIFX9iT4gIZ4qiIIoRW0S1MbNjSi+73ptH+aM49ThgZGOxt6dOXvm7Hof3r/F6dkpBv4AgwHhw/cb9Ho9vDk5wXA4VPC/3+/rXpvZ4798geHrV3gn2QujBOEuBKOqKtQ4jtpY/C8q54AkxuXFBT6en8ObzWaYTCZ4CgIsVmtsF1OsH74DRYW72zEyZ2BzgzzP9cCiKFCWJayRdWuRJAni7RaLqXDMpvBYQBhpEA6kjz8R3n4G5ODx1RVSZ5GlqTYySNxmJ+pIWlDlPjxuPCPTkVscxqIVda0gCXMbW1G32+0QR5HmI0IjfpWiyGSxFlM5IwxDRNJA4tVqdTiE2Gw2yLJMiZfLZUNoDD0ST0wOK+oz5WlUdNUcLmq/RsLRaKTKODp5vO64pasRrO9xM/2iDa5sxmvVEGzqekhvU/GYKrnmcZGj8daMoTSaHqMV1lXYfnczSaiQUML5fK6+tD5Zy1MbFUkSaxMVsLiuK61jpEkKx9ehQszeNiHkGwzkDdJUoqpKRaNAUHUV/uOlwBxd6t5DHVlMJfTRWqvl0Z97bfz19Q7fxr/pKq6vP4HGrH88wgVPMFL/fLE5/gIZ5YMGJbRVMQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Nucleo datastory project screenshot",
            "title": "Nucleo datastory project screenshot",
            "src": "/static/a4d3690a486a9414a233772cc579ebf1/f570d/nucleo.png",
            "srcSet": ["/static/a4d3690a486a9414a233772cc579ebf1/0783d/nucleo.png 240w", "/static/a4d3690a486a9414a233772cc579ebf1/782f4/nucleo.png 480w", "/static/a4d3690a486a9414a233772cc579ebf1/f570d/nucleo.png 960w", "/static/a4d3690a486a9414a233772cc579ebf1/8d0ff/nucleo.png 1440w", "/static/a4d3690a486a9414a233772cc579ebf1/2f950/nucleo.png 1600w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`General guidelines`}</h2>
    <ul>
      <li parentName="ul">{`Please use the 24px grid. This will ensure a consistent level of icon detail across Datastory products. Exceptions include flags that are 512px wide.`}</li>
      <li parentName="ul">{`Use outlined icons instead of filled ones where possible.`}</li>
      <li parentName="ul">{`Use short names that are ideally only one word (e.g. share, twitter, envelope etc.)`}</li>
      <li parentName="ul">{`In cases where an icons does include more than one word, use underscore (e.g. arrow_right)`}</li>
      <li parentName="ul">{`Rename icons after you have added them to `}<inlineCode parentName="li">{`Datastory UI icons`}</inlineCode>{`.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Use the 24px grid for icons.`}</em></p>
    </blockquote>
    <h2>{`Add new icons`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`First check that the icon you need is not already included in the Datastory project on Nucleo.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go under `}<inlineCode parentName="p">{`Projects > Datastory > Datastory UI icons`}</inlineCode>{` and make sure you are synced with the latest version of the project.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Search for and select the icon you would like to add to the Datastory set.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add icon to `}<inlineCode parentName="p">{`Projects > Datastory > Datastory UI icons`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go under the Projects tab and select `}<inlineCode parentName="p">{`My projects > Datastory > Datastory UI icons`}</inlineCode></p>
      </li>
    </ol>
    <p>{`6) Rename the new icon by selecting it and renaming it in the Info sidebar.`}</p>
    <h2>{`Add custom icons`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to `}<a parentName="p" {...{
            "href": "https://www.dropbox.com/s/alve7wkkx7ub7u8/custom-icons.sketch?dl=0"
          }}><inlineCode parentName="a">{`custom-icons.sketch`}</inlineCode></a>{` and create your icon there.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Export the new custom icon as svg.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Under `}<inlineCode parentName="p">{`My sets`}</inlineCode>{` in Nucleo, create a `}<inlineCode parentName="p">{`custom-icons`}</inlineCode>{` set. This is where you will continue adding the custom icons.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Drag and drop the new custom svgs into the `}<inlineCode parentName="p">{`custom-icons`}</inlineCode>{` set and click `}<inlineCode parentName="p">{`import`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the new custom icons to `}<inlineCode parentName="p">{`Datastory UI icons`}</inlineCode>{` project as usually (See "Add new icons section").`}</p>
      </li>
    </ol>
    <h2>{`Replace existing icons`}</h2>
    <p>{`When replacing an icon, make sure to delete the old version before adding a new one. If you don't delete the old version, the new icon will be renamed to something like `}<inlineCode parentName="p">{`iconname-2`}</inlineCode>{`, and will not work with the `}<inlineCode parentName="p">{`<Icon />`}</inlineCode>{` component.`}</p>
    <h2>{`Export icons`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Once you made your changes, select the Datastory UI icons project. Make sure you select the whole project, and not just one icon.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You can either right click on the project name in the sidebar and choose `}<inlineCode parentName="p">{`Export`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose `}<inlineCode parentName="p">{`save as SVG`}</inlineCode>{`, `}<inlineCode parentName="p">{`SVG symbol`}</inlineCode>{` and check `}<inlineCode parentName="p">{`Remove inline colors`}</inlineCode>{` and `}<inlineCode parentName="p">{`Remove stroke-width values`}</inlineCode>{`.`}</p>
      </li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9b40b266f418b58caea064cc98d0ff4e/ddfd0/nucleo-export.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.53965360072927%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABdElEQVQ4y5WTTVLCQBCFc1VIsHDhymu48SYuvIVHYIcIQgiTv0kySSbPfhMHsKQQu+rVJJPpj9fdQ/D4cIfoPsR0GiIMuU6PKzWZTDCbzZz47Pe9/NkoijCfzxEUpUalK1RVBa01mqYR1ej7/k/xLPO8mB8odcCBOozKshybzSfatnVJXdf9gFhrMQyDEwE+j1JKEahwkgALOZS30PKLo9vRsX82xjgYg+8e5BWkaQovfqzrWtSI00xcGpTSjkK3zg3dMbxLniXknHERyH7kee5KNIalW7dXluWxBTcBWTITB9u7lQkEdNJP31O6+5dD01TYqhYfmy3i3U6cFg7O3jH8QBg3AZu6kqEYLN9XWK/XiOP4CBhh0sPv9SagLzlJEuz3idtj2YRZS/BJpvmzh8pN0wN8nMMybbGMe6wSK1esljxelytATpKlnA+A4qQJfFu0eHqp8PxaY7GSyRdK/hhXgFx5ZXgPL6nIM+hyVJalv0r+AlpyyTytJMaIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Nucleo export screenshot",
            "title": "Nucleo export screenshot",
            "src": "/static/9b40b266f418b58caea064cc98d0ff4e/f570d/nucleo-export.png",
            "srcSet": ["/static/9b40b266f418b58caea064cc98d0ff4e/0783d/nucleo-export.png 240w", "/static/9b40b266f418b58caea064cc98d0ff4e/782f4/nucleo-export.png 480w", "/static/9b40b266f418b58caea064cc98d0ff4e/f570d/nucleo-export.png 960w", "/static/9b40b266f418b58caea064cc98d0ff4e/8d0ff/nucleo-export.png 1440w", "/static/9b40b266f418b58caea064cc98d0ff4e/a987b/nucleo-export.png 1920w", "/static/9b40b266f418b58caea064cc98d0ff4e/ddfd0/nucleo-export.png 2194w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This will export 3 files: a `}<inlineCode parentName="p">{`demo.html`}</inlineCode>{`, an `}<inlineCode parentName="p">{`icons.svg`}</inlineCode>{` and a `}<inlineCode parentName="p">{`style.css`}</inlineCode>{`. You only need `}<inlineCode parentName="p">{`icons.svg`}</inlineCode>{`.`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">
        <p parentName="li">{`Rename `}<inlineCode parentName="p">{`icons.svg`}</inlineCode>{` to `}<inlineCode parentName="p">{`Datastory-icons.svg`}</inlineCode>{` and add it to the Datastory website `}<a parentName="p" {...{
            "href": "https://github.com/datastory-org/website/"
          }}>{`repo`}</a>{` under images.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Replace the old `}<inlineCode parentName="p">{`Datastory-icons.svg`}</inlineCode>{` file with the new one you created. Commit and merge your changes and Voila, you are done!`}</p>
      </li>
    </ol>
    <IconListing mdxType="IconListing" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      