import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.array.sort";
import React from "react";
import Icon, { Flag } from "../../../packages/Icon/src";
import Div from "../../../packages/Div/src";
import Flex from "../../../packages/Flex/src";
import { Heading } from "../../../packages/Typography/src";
import iconSpriteSheet from "!!raw-loader!../../images/datastory-icons.svg"; // eslint-disable-line

import flagSpriteSheet from "!!raw-loader!../../images/datastory-flags.svg"; // eslint-disable-line

var parseSprite = function parseSprite(sprite) {
  return sprite.match(/<symbol id="\w+"/gm).map(function (d) {
    return d.slice(12, -1);
  }).sort(function (a, b) {
    return a.localeCompare(b);
  });
};

var icons = parseSprite(iconSpriteSheet);
var flags = parseSprite(flagSpriteSheet);

var Listing = function Listing(_ref) {
  var items = _ref.items,
      type = _ref.type;
  return React.createElement(Flex, null, items.map(function (item) {
    return React.createElement(Div, {
      key: item,
      width: [1, 1 / 2, 1 / 3, 1 / 4]
    }, React.createElement("div", {
      style: {
        padding: "3rem 1rem",
        textAlign: "center",
        border: "1px solid #eee"
      }
    }, type === "icon" ? React.createElement(Icon, {
      name: item
    }) : React.createElement(Flag, {
      name: item
    }), React.createElement("div", {
      style: {
        paddingTop: "1rem",
        color: "#666"
      }
    }, item)));
  }));
};

var IconListing = function IconListing() {
  return React.createElement(React.Fragment, null, React.createElement(Heading, {
    as: "h2",
    mt: "0",
    mb: "2rem"
  }, "Preview"), React.createElement("p", null, "Below you can find all the icons and flags available in the sprite sheet."), React.createElement(Heading, {
    as: "h3",
    mt: "0",
    mb: "2rem"
  }, "Icons"), React.createElement(Listing, {
    items: icons,
    type: "icon"
  }), React.createElement(Heading, {
    as: "h3",
    mt: "0",
    mb: "2rem"
  }, "Flags"), React.createElement(Listing, {
    items: flags,
    type: "flag"
  }));
};

export default IconListing;